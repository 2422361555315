<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :width="400"
    :destroyOnClose="true"
    wrapClassName="pay-modal"
  >
    <template #closeIcon></template>
    <div class="flex-center-between info-text">
      <span>{{$t('common.pay_7')}}</span>
      <span class="order-price">{{ info.currency }} {{ info.price }}</span>
    </div>
    <div class="flex-center-between info-text">
      <span>{{$t('common.pay_8')}}</span>
      <span>{{ info.order_no }}</span>
    </div>
    <div class="qrcode flex">
      <vue-qr :text="info.qrcode" :size="250" :margin="20"  :logoScale="0.2"></vue-qr>
    </div>
    <div class="info-tips">{{$t('common.pay_9')}}</div>
    <div class="pay-modal-footer flex-center-between">
      <van-button @click="onColseHandler" plain type="primary" color="#979797" class="pay-modal-btn">
        {{$t('common.pay_10')}}
      </van-button>
      <van-button @click="onCheckOrder" type="primary" color="#2564f6" class="pay-modal-btn">
        {{$t('common.pay_11')}}
      </van-button>
    </div>
  </a-modal>
</template>

<script>
  import VueQr from 'vue-qr'
  export default {
    name: 'PayModal',
    components: {
      VueQr,
    },
    props:{
      visible:{
        type:Boolean,
        default:false
      },
      info:{
        type:Object,
      }
    },
    data() {
      return {
        polling:false,
        POLLING_INTERVAL: 3000, // 轮询间隔时间
      }
    },
    watch:{
      visible(val){
        if(this.info.order_no&&val){
          this.polling = true
          this.pollPaymentStatus()
        }else{
          this.polling = false
        }
      }
    },
    beforeDestroy() {
      this.polling = false
    },
    methods: {
      async pollPaymentStatus(){
        const { order_no,order_type } = this.info
        const apiUrl = order_type == 1 ? this.APIURL.ORDER.FLOWSTATUS : this.APIURL.ORDER.SIMSTATUS
        try {
          // 轮询直到订单状态为支付成功或超时
          while (this.polling&&order_no) {
              // 发起请求检查订单状态
              const {data:{info:{ptime}}} = await this.$http.post(apiUrl, {order_no},false)
              // 如果订单状态为支付成功，则结束轮询
              if (ptime!==0) {
                  console.log('订单支付成功');
                  this.polling = false
                  // 跳转到结果页
                  this.$router.push({ name: 'Result',query:{r:'success',order_no,order_type}});
                  break;
              }

              // 如果订单状态为其他情况，打印日志并继续轮询
              console.log('订单状态为：', ptime);

              // 等待一段时间后继续轮询
              await new Promise(resolve => setTimeout(resolve, this.POLLING_INTERVAL));
          }
      } catch (error) {
        this.polling = false
        console.error('轮询订单状态时出错：', error);
      }
      },
      onColseHandler(){
        this.polling = false
        this.$emit('handle','cancel')
      },
      onCheckOrder(){
        this.$emit('handle','success')
      }
    },
  }
</script>

<style lang="scss" scoped>
.pay-modal{
  .info-text{
    color:#666666;
    font-size:14px;
    line-height: 2;
    .order-price{
      color:#B50000;
      font-size: 24px;
    }
  }
  .info-tips{
    color:#666666;
    font-size:14px;
    text-align: center;
    margin-bottom: 20px;
  }
  .pay-modal-footer{
    border-top: 1px solid #E5E5E5;
    padding-top: 20px;
    .pay-modal-btn{
      flex:1;
      &:first-child{
        margin-right: 10px;
      }
    }
  }
}
</style>