<template>
  <div id="pay">
    <div class="container">
      <div class="checkout-title">{{ $t('common.pay_1') }}</div>
      <div class="checkout-content">
        <div class="subtitle">{{ $t('common.pay_2') }}</div>
        <a-row :gutter="[30, 10]" class="product">
          <a-col :xs="24" :sm="24" :md="24" :lg="10" :xl="8">
            <img class="product-img" :src="productInfo.icon" alt="">
          </a-col>
          <a-col :xs="24" :sm="24" :md="24" :lg="10" :xl="8">
            <ul class="product-info">
              <li>
                <span>{{ productInfo.district_name }}</span>
                <span>{{ productInfo.spec }}</span>
              </li>
              <li v-if="orders.order_type">
                {{ $t('common.title_5') }}：<span class="iccid">{{ simNumber }}</span>
              </li>
              <li v-if="!orders.order_type">{{ $t('common.title_4') }}：<span >{{ productInfo.number }}</span></li>
              <li>{{ $t('common.pay_3') }}<span>{{ paymentInfo.last_active_date }}</span></li>
              <li>
                <span>{{ productInfo.currency }} {{ unitPrice }}</span>
                <span v-if="!orders.order_type"> / {{ $t('common.each') }}</span>
              </li>
              <li class="card-fee-tips" v-if="!orders.order_type">
                <span>{{$t('common.card_fee')}}</span>
              </li>
            </ul>
          </a-col>
        </a-row>
        <div v-if="!orders.order_type && !$route.query.order_no">
          <div class="subtitle">{{ $t('common.title_6') }}</div>
          <div class="tag-wrapper">
            <div class="tag-item tag-active">
              <input type="radio" :checked="true" />
              <img class="tag-icon" src="../assets/tab_1.png" alt="" />
              {{ $t('common.pay_14') }}
            </div>
          </div>
          <div>
            <div class="tab-title flex-center-start">
              <span>{{ $t('common.title_7') }}</span>
              <div class="add-address flex" @click="addressFormShow = true">
                <a-button class="add-btn flex" type="primary" ghost >
                  <a-icon type="plus" />
                </a-button>
                <span>{{ $t('common.btn_3') }}</span>
              </div>
            </div>
            <div class="address">
              <ul class="address-list" v-if="addressList.length">
                <li v-for="(item, index) in addressList" :key="index" style="cursor: pointer"
                    @click="onAddressSelect(item)">
                  <div class="header flex-center-start">
                    {{ $t('mine.add_11') }} {{ index + 1 }}
                    <van-tag style="margin-left:10px" mark type="primary" v-show="item.is_default">{{ $t('mine.add_18')
                      }}</van-tag>
                  </div>
                  <div class="address-cont flex-center-between">
                    <div class="left">
                      <input type="radio" :checked="address.address_id == item.address_id" />
                    </div>
                    <div class="mid">
                      <div class="name">
                        <div class="item two-line-ellipsis" :title="item.consignee">
                          <span class="label">{{ $t('mine.add_13') }}</span>
                          {{ item.consignee }}
                        </div>
                        <div class="item two-line-ellipsis" :title="item.mobile">
                          <span class="label">{{ $t('mine.add_14') }}</span>
                          {{ item.mobile }}
                        </div>
                      </div>
                      <div class="address">
                        <div class="item two-line-ellipsis" :title="`${item.district} ${item.address}`">
                          <span class="label">{{ $t('mine.add_11') }}：</span>
                          {{ item.district }}{{ item.address }}
                        </div>
                        <div class="item two-line-ellipsis" :title="item.postcode">
                          <span class="label">{{ $t('mine.add_15') }}</span>
                          {{ item.postcode }}
                        </div>
                      </div>
                    </div>
                    <div class="right">
                      <span class="colorBtn" @click.stop="onEditClick(item.address_id)">{{ $t('mine.add_19') }}</span>
                    </div>
                  </div>
                </li>
              </ul>
              <van-notice-bar v-else color="#fff" background="#B50000" left-icon="warning-o">
                {{ $t('common.title_8') }}
              </van-notice-bar>
            </div>
            <div class="tab-title flex-center-start">{{ $t('common.title_9') }}</div>
            <div class="tag-wrapper" v-if="expressList.length">
              <div class="tag-item" v-for="(item, index) in expressList" :key="index"
                   :class="{ 'tag-active': express.kd_name == item.shipping_code }" @click="onExpressSelect(item)">
                <input type="radio" :checked="express.kd_name == item.shipping_code" />
                <img class="tag-icon" src="../assets/express-icon.png" alt="" />
                {{ item.shipping_name }}
                <span v-if="item.shipping_price > 0">(+{{ productInfo.currency }}{{ item.shipping_price }})</span>
              </div>
            </div>
            <div style="margin: 20px 0" v-else>
              <van-notice-bar color="#fff" background="#B50000" left-icon="warning-o">
                {{ $t('common.title_7') }}
              </van-notice-bar>
            </div>
          </div>
        </div>
        <div class="subtitle">{{ $t('common.pay_4') }}</div>
        <div class="payment-wrapper">
          <div class="payment-info" v-for="(group, i) in paymentInfo.payment" :key="i">
            <div class="payment-title">{{ group.group }}</div>
            <div class="tag-wrapper">
              <div class="tag-item" v-for="(item, index) in group.list" :key="index"
                   @click="orders.payment = item.payment" :class="{ 'tag-active': orders.payment === item.payment }">
                <input type="radio" :checked="orders.payment === item.payment" />
                <img class="tag-icon" :src="item.img" alt="" srcset="">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="checkout-footer flex-center-end">
        <div class="amount">
          <div class="card-fee flex" v-if="!orders.order_type">
            <span>{{$t('common.card_fee')}}</span>
          </div>
          <div class="flex-center-between">
            <span class="amount-label">{{ $t('common.pay_5') }}：</span>
            <span class="price">{{ productInfo.currency }} {{ total }}</span>
          </div>
          <div class="flex-center-between" v-if="discountAmount">
            <span class="amount-label">{{ $t('common.coupon') }}：</span>
            <span class="discount">-{{ productInfo.currency }} {{ discountAmount }}</span>
          </div>
        </div>
        <a-button type="buynow" :loading="isLoading" @click="onCreateOrderHandler">
          {{ $t('common.pay_6') }}
        </a-button>
      </div>
    </div>
    <pay-modal @handle="handlePayModalCallback" :visible="payModalVisible" :info="{
      currency: productInfo.currency,
      order_no: orders.order_no,
      order_type: orders.order_type,
      price: total,
      qrcode
    }" />
    <add-address :show="addressFormShow" ref="edit" @childFn="(status) => { addressFormShow = status }"
         @updata="getAddressHandler" />
  </div>
</template>
<script>
import { message,Modal } from 'ant-design-vue'
import BigNumber from 'bignumber.js';
import AddAddress from '../components/AddAddress';
import PayModal from '../components/PayModal.vue'
export default {
  name: 'Pay',
  components: { AddAddress, PayModal },
  data() {
    return {
      isLoading: false,
      qrcode: '',
      payModalVisible: false,
      productInfo: {},
      addressFormShow: false,
      addressList: [],
      expressList: [],
      paymentInfo: {
        last_active_date: ''
      },
      address: {},
      express: {
        kd_name: '',
        kd_fee: 0
      },
      orders: {
        payment: '',
        order_no: '',
        order_type: ''
      }
    };
  },
  computed: {
    //应付
    total() {
      const { order_type } = this.orders // 订单类型
      const { price, number } = this.$route.query
      const quantity = Number(number);// 数量
      const topup_order = BigNumber(price).minus(this.discountAmount).toFixed(2);// 充值订单
      const card_fee = BigNumber(8).times(quantity);// 卡费
      const sim_order = BigNumber(price).times(quantity).plus(card_fee).minus(this.discountAmount).toFixed(2)// 购卡订单
      return order_type ? topup_order : sim_order
    },
    //单价
    unitPrice(){
      const { order_type } = this.orders
      const { price } = this.$route.query
      const unit_price = BigNumber(price);
      return  order_type ? unit_price.toFixed(2) : unit_price.plus(8).toFixed(2)
    },
    // 优惠金额
    discountAmount(){
      const { discount_amount } = this.$route.query
      const discoun = Number(discount_amount)
      return discoun ? discoun.toFixed(2) : 0
    },
    simNumber() {
      return this.productInfo.sim_number
        .trim()
        .replace(/\s/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .trim();
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    handlePayModalCallback(status) {
      const { order_type, order_no } = this.orders
      if (status === 'cancel') {
        this.payModalVisible = false
        this.$router.push({ name: 'Result', query: { r: 'fail', order_no, order_type } });
        return
      }
      if (status === 'success') {
        this.handleCheckOrder()
      }
    },
    async handleCheckOrder() {
      try {
        const { order_type, order_no } = this.orders
        const apiUrl = order_type ? this.APIURL.ORDER.FLOWSTATUS : this.APIURL.ORDER.SIMSTATUS
        const { data: { info: { ptime } } } = await this.$http.post(apiUrl, { order_no }, false)
        if (ptime !== 0) {
          this.$router.push({ name: 'Result', query: { r: 'success', order_no, order_type } });
        }
        message.warning(this.$t('common.pay_12'))
      } catch (error) {
        message.warning(error.msg)
      }
    },
    async init() {
      const { name, plan_id, spec, price, cover, number, sim_number, type, order_no, currency } = this.$route.query
      this.productInfo = { district_name: name, icon: cover, price, currency, plan_id, number, spec, sim_number }
      this.orders = { payment: '', order_no, order_type: Number(type) }
      const payments = await this.$http.get(this.APIURL.ORDER.PAY)
      this.paymentInfo = payments.data
      if (!this.orders.order_type) {
        const address = await this.$http.post(this.APIURL.MYDATA.ADDRESSLIST, {}, false)
        this.setAddressDefault(address.data.list)
      }
    },
    setAddressDefault(result) {
      this.addressList = result
      let address = this.addressList.find(item => item.is_default)
      if (address) this.onAddressSelect(address)
    },
    async onAddressSelect(item) {
      const { mobile, district, address, consignee, postcode, address_id } = item;
      if (!address_id ||address_id===this.address.address_id) return
      this.address = { mobile, address: district + address, name: consignee, postcode, address_id }
      const { data } = await this.$http.post(this.APIURL.ORDER.KDLIST, { district })
      this.expressList = data.list
      this.onExpressSelect(data.list[0])
    },
    onExpressSelect(item) {
      this.express.kd_name = item.shipping_code
      if (this.express.kd_fee != item.shipping_price) {
        this.express.kd_fee = item.shipping_price
      }
    },
    async handleHasPlan(simcard) {
      const { data: {list:hasData,available_attention} } = await this.$http.post(this.APIURL.MYCARDS.HASPLAN, {simcard}, true)
      if(hasData.length>0){
        return new Promise((resolve, reject) => {
           Modal.info({
            width:440,
            title: this.$t('mycards.topup_confirm'),
            content: h => (
              <div>
                {
                  available_attention.map((item,index)=>{
                    return <div key={index}>{item}</div>
                  })
                }
              </div>
            ),
            okText: this.$t('mycards.next'),
            autoFocusButton:null,
            maskClosable:true,
            onOk() {
              resolve(true)
            },
            onCancel() {
              reject(false)
            },
          })
        })
      }
      return Promise.resolve(true)
    },
    async onCreateOrderHandler() {
      const { status,order_no } = this.$route.query
      const { payment, order_type } = this.orders
      const { plan_id, district_name, icon, spec, sim_number, number } = this.productInfo
      if (!order_type && !this.address.address_id && !status) return message.warning(this.$t('common.title_7'));
      if (!payment) return message.warning(this.$t('common.pay_4'));
      if (order_type&&sim_number) {
        const next = await this.handleHasPlan(sim_number)
        if(!next) return
      }
      if (order_no) return this.orderPayHandler();
      const apiUrl = order_type ? this.APIURL.ORDER.FLOWORDER : this.APIURL.ORDER.SIMORDER
      const parms = {
        ...(!order_type && this.address),
        ...(!order_type && this.express),
        ...(!order_type && { number }),
        ...(order_type && { sim_number }),
        type: order_type,
        plan_id,
        district_name,
        icon,
        spec,
      }
      try {
        this.isLoading = true;
        const result = await this.$http.post(apiUrl, parms)
        this.isLoading = false;
        this.orders.order_no = result.data.order_no;
        this.orderPayHandler()
      } catch (error) {
        message.error(error.msg);
        this.isLoading = false;
      }
    },
    async orderPayHandler() {
      try {
        this.isLoading = true;
        const { origin } = window.location
        const { payment } = this.orders
        const parms = {
          ...this.orders,
          ...(payment === 'paypal' && { returnUrl: `${origin}/result/` }),
        }
        const { data: { parameter: { checkout, qrcode } } } = await this.$http.post(this.APIURL.ORDER.PAYCODE, parms)
        if (payment === 'paypal') {
          window.location.replace(checkout)
          return
        }
        this.payModalVisible = true
        this.qrcode = qrcode;
        this.isLoading = false;
      } catch (error) {
        message.error(error.msg);
        this.isLoading = false;
      }
    },
    async getAddressHandler() {
      const { data } = await this.$http.post(this.APIURL.MYDATA.ADDRESSLIST, {}, false)
      this.addressList = data.list
    },
    onEditClick(id) {
      this.addressFormShow = true;
      this.$refs.edit.onEdit(id);
    },
  },
};
</script>
<style lang="scss" scoped>
#pay {
  .checkout-title {
    color: $color;
    font-size: 20px;
    font-weight: bold;
    margin: 40px 0 20px 0;
  }

  .checkout-content {
    padding-bottom: 40px;
    border-bottom: 1px solid #f2f3f6;
    border-top: 1px solid #f2f3f6;

    .subtitle {
      color: #222222;
      font-size: 18px;
      font-weight: bold;
      padding: 10px 20px;
      margin: 30px 0;
      border-left: 3px solid $color;
      background-color: rgba(37, 100, 246, 0.1);
    }

    .product-img {
      width: 100%;
      object-fit: cover;
      border-radius: 6px;
    }

    .product-info {
      color: #222222;
      font-size: 20px;

      .original-price {
        font-size: 14px;
        margin-left: 10px;
      }

      li {
        padding-bottom: 20px;

        span {
          font-weight: bold;
        }

        .iccid {
          user-select: all;
          color: $color;
        }
        &:first-child {
          display: flex;
          flex-direction: column;
          padding-bottom: 0;

          span {
            padding-bottom: 20px;

            &:last-child {
              color: $color;
            }
          }
        }

        &:last-child {
          padding-bottom: 10px;
        }
      }
      .card-fee-tips{
        color:$color;
        font-size: 14px;
      }
    }

    .tab-title {
      font-size: 18px;
      color: #222222;
      font-weight: 600;
    }

    .tag-wrapper {
      margin: 30px 0;
      display: flex;

      .tag-item {
        cursor: pointer;
        padding: 15px 20px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: 1px solid #DCDFE6;

        .tag-icon {
          width: 36px;
          height: auto;
          margin: 0 10px;
        }
      }

      .tag-active {
        border-color: #1e6dea;
      }
    }

    .address {
      padding: 20px 0 40px 0;
    }
  }

  .checkout-footer {
    z-index: 1;
    padding: 20px 0;
    position: sticky;
    bottom: 0;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    .amount {
      user-select: none;
      font-size: 18px;
      color: #222222;
      margin-right: 30px;

      .amount-label {
        min-width: 200px;
        text-align: end;

        .info-circle {
          cursor: pointer;
          margin-right: 10px;
        }
      }

      .original-price {
        color: #666;
        font-size: 16px;
      }

      .price {
        color: #b50000;
        font-size: 24px;
      }

      .discount {
        color: #b50000;
        font-size: 16px;
      }
      .card-fee{
        color: #666;
        font-size: 14px;
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: 1024px) {
  #pay {
    .checkout-title {
      font-size: 18px;
      margin: 20px 0 15px 0;
    }

    .checkout-content {
      padding: 0;
      border: none;

      .subtitle {
        font-size: 16px;
        padding: 10px;
        margin: 15px 0;
      }

      .product-info {
        font-size: 16px;
        padding: 0 10px 0 10px;

        .original-price {
          font-size: 12px;
          margin-left: 10px;
        }

        li {
          padding-bottom: 10px;

          &:first-child {
            flex-wrap: wrap;
            flex-direction: row;
            padding-bottom: 10px;

            span {
              padding-bottom: 0;

              &:first-child {
                margin-right: 15px;
              }
            }
          }
        }
      }

      .tab-title {
        font-size: 14px;
      }

      .address {
        padding: 0 0 20px 0;
      }

      .tag-wrapper {
        margin: 20px 0;
        display: flex;
        flex-direction: column;

        .tag-item {
          margin-right: 0;
          margin-bottom: 20px;
          font-size: 14px;

          .tag-icon {
            width: 36px;
            height: auto;
            margin: 0 10px;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }

        .tag-active {
          border-color: #1e6dea;
        }
      }
    }

    .checkout-footer {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-content: center;

      .amount {
        margin-right: 10px;
        font-size: 14px;

        .amount-label {
          font-size: 14px;
          min-width: 80px;

          .info-circle {
            margin-right: 6px;
          }
        }

        .original-price {
          color: #666;
          font-size: 14px;
        }

        .price {
          font-size: 20px;
        }

        .discount {
          font-size: 14px;
        }
        .card-fee{
          justify-content: center;
        }
        @media (max-width:320px) {
          font-size: 12px;

          .amount-label {
            font-size: 12px;
          }

          .price {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>